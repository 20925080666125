@charset "UTF-8";
/** töréspontok */
/** Content szélesség */
/** Betű méretek */
/* Header elemek betű méretei */
/** notyf */
/** Transition */
/** Form */
/** Input mezők */
/** select */
/** checkbox */
/** radio */
/** Gomb */
/** Form hiba mező */
/** Mobil menü */
/** Egyéb */
/** Linkek */
/* Link szín */
/* Gombok színei */
/* Input mezők színei */
/* Checkbox színek */
/** */
/** select */
/** notyf */
/** Mobil menü */
/** Betű méretekkel kapcsolatos mixin-ek külön kerültek */
.light-gray-bg {
  background-color: #F7F7F7;
}

.color-light-gray {
  color: #F7F7F7;
}

.gray-bg {
  background-color: #C0C4CB;
}

.color-gray {
  color: #C0C4CB;
}

.color-dark-gray {
  color: #757982;
}

.purple-bg {
  background-color: #441196;
}

.blue-bg {
  background-color: #029CE0;
}

.color-blue {
  color: #029CE0;
}

.light-blue-bg {
  background: #D3EAF5;
}

.color-light-blue {
  color: #D3EAF5;
}

.orange-bg {
  background: #F46939;
}

.color-orange {
  color: #F46939;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
*:before, *:after {
  box-sizing: border-box;
}
* p:last-of-type {
  margin-bottom: 0px;
}

body {
  display: block;
  background: #ffffff;
  font-family: "Catamaran", Arial, sans-serif;
  color: #393939;
  letter-spacing: 0.2px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  -webkit-overflow-scrolling: touch;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
}
body.noscroll {
  overflow-y: hidden;
}

a {
  font-weight: 600;
  transition: color 0.25s ease;
  color: #029CE0;
  text-decoration: underline;
}
a.secondary {
  color: #393939;
}

p {
  margin-bottom: 22px;
}
p:last-of-type, p:empty {
  margin-bottom: 0px;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.display-grid {
  display: grid;
}

.display-inline-grid {
  display: inline-grid;
}

.display-none, .hidden, .d-none {
  display: none;
}

.display-table {
  display: table;
}

.display-table-row {
  display: table-row;
}

.display-table-cell {
  display: table-cell;
}

.flex-wrap {
  flex-wrap: wrap;
}

.direction-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.zero-fsize {
  font-size: 0px;
}

.zero-lheight {
  line-height: 0px;
}

.zero-text {
  font-size: 0px;
  line-height: 0px;
}

.primary-text {
  font-size: 16px;
  line-height: 22px;
}

.primary-fsize {
  font-size: 16px;
}

.primary-lheight {
  line-height: 22px;
}

.secondary-text {
  font-size: 14px;
  line-height: 20px;
}

.secondary-fsize {
  font-size: 14px;
}

.secondary-lheight {
  line-height: 20px;
}

.tertiary-text {
  font-size: 12px;
  line-height: 16px;
}

.tertiary-fsize {
  font-size: 12px;
}

.tertiary-lheight {
  line-height: 16px;
}

.lead-text {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.4px;
}

.lead-fsize  {
  font-size: 18px;
}

.lead-lheight {
  font-size: 26px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.absolute-center {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.color-transition {
  transition: color 0.25s ease;
}

.bg-transition {
  transition: background-color 0.25s ease;
}

.transform-transition {
  transition: transform 0.25s ease;
}

.full-width {
  width: 100%;
}

.normal-fweight {
  font-weight: 400;
}

.semi-fweight {
  font-weight: 600;
}

b, strong, .bold-fweight, .bold {
  font-weight: 700;
}

ul, ol {
  list-style: none;
}

.clear {
  clear: both;
  width: 100%;
  display: block;
  height: 0px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.bg-image-cover {
  background-repeat: none;
  background-position: center center;
  background-size: cover;
}

.bg-position-y-top {
  background-position-y: top;
}

.bg-position-y-center {
  background-position-y: center;
}

.bg-position-y-bottom {
  background-position-y: bottom;
}

.bg-position-x-left {
  background-position-x: left;
}

.bg-position-x-center {
  background-position-x: center;
}

.bg-position-x-right {
  background-position-x: right;
}

.bg-white {
  background: #ffffff;
}

.color-white {
  color: #ffffff;
}

.color-black {
  color: #393939;
}

.color-inherit {
  color: inherit;
}

.bg-black {
  background: #393939;
}

.pointer, .cursor-pointer {
  cursor: pointer;
}

.without-underline {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
img.lazy:not(.loaded) {
  opacity: 0;
}

video {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.space-nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.circle {
  border-radius: 50%;
}

.content-width {
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  margin: auto;
  position: relative;
  padding: 0px 50px 0px 50px;
}
.content-width:not(.display-flex):not(.display-grid) {
  display: block;
}
.content-width.thin-content {
  max-width: 840px;
}
.content-width.wide-content {
  max-width: 1300px;
}
.content-width.without-padding {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 1100px;
}
.content-width.without-padding.thin-content {
  max-width: 740px;
}
.content-width.without-padding.wide-content {
  max-width: 1200px;
}

body.scroll-disabled, html.scroll-disabled {
  overflow: hidden;
}

h1, .like-h1,
h2, .like-h2,
h3, .like-h3,
h4, .like-h4 {
  font-weight: 400;
  color: #393939;
  margin: 0px;
}

h4, .like-h4 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

h3, .like-h3 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

h2, .like-h2 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

h1, .like-h1 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.margin-top-zero {
  margin-top: 0px;
}

.margin-top-mini {
  margin-top: 15px;
}

.margin-top-small {
  margin-top: 30px;
}

.margin-top-normal {
  margin-top: 45px;
}

.margin-top-big {
  margin-top: 70px;
}

.margin-bottom-zero {
  margin-bottom: 0px;
}

.margin-bottom-mini {
  margin-bottom: 15px;
}

.margin-bottom-small {
  margin-bottom: 30px;
}

.margin-bottom-normal {
  margin-bottom: 45px;
}

.margin-bottom-big {
  margin-bottom: 70px;
}

.margin-auto {
  margin: auto;
}

.hidden-mail:before {
  content: attr(data-user);
}
.hidden-mail:after {
  content: attr(data-domain);
}

@media all and (min--moz-device-pixel-ratio: 0) {
  a:hover {
    color: #029CE0;
    text-decoration: none;
  }
  a:hover.secondary {
    color: #393939;
  }
}
@media (hover: hover) {
  a:hover {
    color: #029CE0;
    text-decoration: none;
  }
  a:hover.secondary {
    color: #393939;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a:hover {
    color: #029CE0;
    text-decoration: none;
  }
  a:hover.secondary {
    color: #393939;
  }
}
@media screen and (min-width: 601px) {
  .show-from-mobile {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .content-width {
    padding-left: 30px;
    padding-right: 30px;
  }
  .content-width.thin-content {
    max-width: 800px;
  }
}
@media screen and (max-width: 1023px) {
  .margin-top-mini {
    margin-top: 15px;
  }

  .margin-top-small {
    margin-top: 30px;
  }

  .margin-top-normal {
    margin-top: 45px;
  }

  .margin-top-big {
    margin-top: 60px;
  }

  .margin-bottom-mini {
    margin-bottom: 15px;
  }

  .margin-bottom-small {
    margin-bottom: 30px;
  }

  .margin-bottom-normal {
    margin-bottom: 45px;
  }

  .margin-bottom-big {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 600px) {
  body {
    font-size: 14px;
    line-height: 20px;
  }

  .content-width {
    padding-left: 15px;
    padding-right: 15px;
  }

  h4, .like-h4 {
    font-size: 14px;
    line-height: 18px;
  }

  h3, .like-h3 {
    font-size: 16px;
    line-height: 20px;
  }

  h2, .like-h2 {
    font-size: 18px;
    line-height: 22px;
  }

  h1, .like-h1 {
    font-size: 20px;
    line-height: 26px;
  }

  .primary-text {
    font-size: 14px;
    line-height: 20px;
  }

  .primary-fsize {
    font-size: 14px;
  }

  .primary-lheight {
    line-height: 20px;
  }

  .secondary-text {
    font-size: 12px;
    line-height: 16px;
  }

  .secondary-fsize {
    font-size: 12px;
  }

  .secondary-lheight {
    line-height: 16px;
  }

  .tertiary-fsize {
    font-size: 12px;
  }

  .tertiary-lheight {
    line-height: 16px;
  }

  .lead-text {
    font-size: 16px;
    line-height: 24px;
  }

  .lead-fsize {
    font-size: 16px;
  }

  .lead-lheight {
    line-height: 24px;
  }

  .margin-top-mini {
    margin-top: 15px;
  }

  .margin-top-small {
    margin-top: 20px;
  }

  .margin-top-normal {
    margin-top: 20px;
  }

  .margin-top-big {
    margin-top: 30px;
  }

  .margin-bottom-mini {
    margin-bottom: 15px;
  }

  .margin-bottom-small {
    margin-bottom: 20px;
  }

  .margin-bottom-normal {
    margin-bottom: 20px;
  }

  .margin-bottom-big {
    margin-bottom: 30px;
  }

  .hide-on-mobile {
    display: none !important;
  }
}
.search-form {
  width: 180px;
  height: 100%;
  margin-right: 20px;
  background: #dddddd;
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.search-form .field {
  background: transparent;
  border: none;
  height: 100%;
  padding: 8px 0px 8px 15px;
  margin-right: 15px;
  min-height: auto;
  font-size: 12px;
  line-height: 16px;
  border-radius: 0px;
  color: #757982;
  font-weight: 600;
}
.search-form .field:focus {
  border: none;
  background: transparent;
}
.search-form .field:-internal-autofill-selected {
  background: transparent;
}
.search-form .search-button {
  min-height: auto;
  padding: 0px;
  background: transparent;
  border: none;
  color: #757982;
  width: 18px;
  height: 17px;
  min-width: 18px;
}
.search-form .search-button .icon {
  width: 100%;
  height: 100%;
}

.main-header {
  position: relative;
  margin-bottom: 30px;
  z-index: 100;
  padding-bottom: 104px;
}
.main-header .fix-content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.main-header .mobile-menu-btn, .main-header .mobile-profile-btn {
  display: none;
}
.main-header.opened-fix-header .fix-content {
  height: 120px;
  position: fixed;
  top: -120px;
  left: 0px;
  right: 0px;
  z-index: 100;
  transition: transform 0.25s ease;
  transition-delay: 100ms;
  transform: translateY(120px);
}
.main-header a {
  text-decoration: none;
}
.main-header .hello-bar {
  padding: 10px 0px 10px 0px;
  position: relative;
}
.main-header .hello-bar .close-btn {
  width: 20px;
  height: 20px;
  top: 0px;
  right: 10px;
  bottom: 0px;
  margin: auto;
  display: block;
  color: #ffffff;
}
.main-header .hello-bar .close-btn .icon {
  width: 100%;
  height: 100%;
}
.main-header .hello-bar .inner-content {
  gap: 15px 25px;
}
.main-header .hello-bar .button.mini-button {
  min-height: 20px;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.3);
  padding: 4px 5px 4px 5px;
  border-color: transparent;
  color: #ffffff;
  text-transform: none;
  letter-spacing: 0.4px;
}
.main-header .hello-bar .button.mini-button .icon {
  min-width: 4px;
  margin-left: 2.5px;
}
.main-header .profile-content {
  background: #f0f0f0 !important;
}
.main-header .profile-content .inner-content {
  height: 34px;
}
.main-header .profile-content a {
  color: #757982;
}
.main-header .profile-content a .icon {
  margin-right: 5px;
}
.main-header .profile-content a .text {
  display: block;
  font-size: 12px;
  line-height: 16px;
}
.main-header .profile-content .item:after {
  content: "";
  width: 1px;
  min-width: 1px;
  height: 10px;
  display: block;
  background: #757982;
  margin: auto 10px auto 10px;
}
.main-header .profile-content .item:last-of-type:after {
  display: none;
}
.main-header .menu-content {
  box-shadow: 0 5px 9px 0 rgba(71, 71, 75, 0.08);
  background: #ffffff;
}
.main-header .menu-content > .inner-content {
  height: 70px;
}
.main-header .menu-content .logo-content {
  transform: translate(-16px, -4px);
  position: relative;
  width: 201px;
}
.main-header .menu-content .logo-content img {
  position: relative;
  z-index: 2;
}
.main-header .menu-content .logo-content .arrow-outer {
  filter: drop-shadow(0px 7px 6px rgba(71, 71, 75, 0.1));
  display: none;
  display: block;
  position: absolute;
  left: 12px;
  bottom: 0px;
  z-index: 1;
}
.main-header .menu-content .logo-content .arrow-outer .arrow {
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 0% 0%);
  background: #ffffff;
  display: block;
  height: 12px;
  width: 86px;
}
.main-header .main-menu {
  height: 100%;
}
.main-header .main-menu ul {
  height: 100%;
}
.main-header .main-menu li {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 34px;
  position: relative;
}
.main-header .main-menu li:last-of-type {
  margin-right: 0px;
}
.main-header .main-menu .menu-list-item > a:not(.button) .icon {
  color: #029CE0;
  min-width: 8px;
  margin-left: 10px;
  transition: transform 0.25s ease;
}
.main-header .submenu {
  position: absolute;
  background: #ffffff;
  padding: 30px;
  border-radius: 2px;
  box-shadow: 0 0 14px 0 rgba(71, 71, 75, 0.1);
  width: 234px;
  left: 50%;
  top: calc(100% + 8px);
  transform: translateX(-50%);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease;
  transition-delay: 30ms;
}
.main-header .submenu:before {
  content: "";
  width: 0px;
  height: 0px;
  left: 0px;
  right: 0px;
  bottom: 100%;
  margin: auto;
  position: absolute;
  display: block;
  border-style: solid;
  border-width: 0px 6px 7px 6px;
  border-color: transparent transparent #ffffff transparent;
}
.main-header .submenu a {
  font-weight: 400;
  color: #393939;
  text-decoration: none;
  position: relative;
}
.main-header .submenu .row {
  margin-bottom: 20px;
}
.main-header .submenu .row:last-of-type {
  margin-bottom: 0px;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .main-header .hello-bar .button.mini-button:hover {
    background: #ffffff;
    border-color: #ffffff;
    color: #441196;
  }
  .main-header .hello-bar .close-btn:hover {
    opacity: 1;
  }
  .main-header .profile-content a:hover {
    color: #393939;
  }
  .main-header .main-menu li > a:not(.button):hover {
    color: #029CE0;
  }
  .main-header .main-menu li.has-submenu:hover > a {
    color: #029CE0;
  }
  .main-header .main-menu li.has-submenu:hover > a .icon {
    transform: rotate(180deg);
  }
  .main-header .main-menu li.has-submenu:hover .submenu {
    visibility: visible;
    opacity: 1;
  }
  .main-header .main-menu li.has-submenu:hover .submenu:after {
    content: "";
    height: 10px;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 100%;
  }
  .main-header .submenu a:hover {
    color: #029CE0;
  }
}
@media (hover: hover) {
  .main-header .hello-bar .button.mini-button:hover {
    background: #ffffff;
    border-color: #ffffff;
    color: #441196;
  }
  .main-header .hello-bar .close-btn:hover {
    opacity: 1;
  }
  .main-header .profile-content a:hover {
    color: #393939;
  }
  .main-header .main-menu li > a:not(.button):hover {
    color: #029CE0;
  }
  .main-header .main-menu li.has-submenu:hover > a {
    color: #029CE0;
  }
  .main-header .main-menu li.has-submenu:hover > a .icon {
    transform: rotate(180deg);
  }
  .main-header .main-menu li.has-submenu:hover .submenu {
    visibility: visible;
    opacity: 1;
  }
  .main-header .main-menu li.has-submenu:hover .submenu:after {
    content: "";
    height: 10px;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 100%;
  }
  .main-header .submenu a:hover {
    color: #029CE0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main-header .hello-bar .button.mini-button:hover {
    background: #ffffff;
    border-color: #ffffff;
    color: #441196;
  }
  .main-header .hello-bar .close-btn:hover {
    opacity: 1;
  }
  .main-header .profile-content a:hover {
    color: #393939;
  }
  .main-header .main-menu li > a:not(.button):hover {
    color: #029CE0;
  }
  .main-header .main-menu li.has-submenu:hover > a {
    color: #029CE0;
  }
  .main-header .main-menu li.has-submenu:hover > a .icon {
    transform: rotate(180deg);
  }
  .main-header .main-menu li.has-submenu:hover .submenu {
    visibility: visible;
    opacity: 1;
  }
  .main-header .main-menu li.has-submenu:hover .submenu:after {
    content: "";
    height: 10px;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 100%;
  }
  .main-header .submenu a:hover {
    color: #029CE0;
  }
}
@media (pointer: coarse) {
  .main-header {
    padding-bottom: 94px;
  }
  .main-header.opened-fix-header .fix-content {
    height: 110px;
    top: -110px;
    transform: translateY(110px);
  }
  .main-header .menu-content {
    box-shadow: 0 5px 12px 4px rgba(71, 71, 75, 0.08);
  }
  .main-header .menu-content > .inner-content {
    height: 60px;
  }
  .main-header .menu-content .logo-content {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    width: 160px;
    transform: translateY(-10px);
  }
  .main-header .menu-content .logo-content img {
    max-height: none;
  }
  .main-header .menu-content .logo-content .arrow-outer {
    display: none;
  }
  .main-header .mobile-menu-btn {
    padding: 5px;
    width: 36px;
    height: 31px;
    display: block;
    transform: translateX(-5px);
    color: #029CE0;
    cursor: pointer;
  }
  .main-header .mobile-menu-btn .icon {
    width: 100%;
    height: 100%;
  }
  .main-header .main-menu li.site-item {
    display: none;
  }
}
@media screen and (max-width: 1180px) {
  .main-header {
    padding-bottom: 94px;
  }
  .main-header.opened-fix-header .fix-content {
    height: 110px;
    top: -110px;
    transform: translateY(110px);
  }
  .main-header .menu-content {
    box-shadow: 0 5px 12px 4px rgba(71, 71, 75, 0.08);
  }
  .main-header .menu-content > .inner-content {
    height: 60px;
  }
  .main-header .menu-content .logo-content {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    width: 160px;
    transform: translateY(-10px);
  }
  .main-header .menu-content .logo-content img {
    max-height: none;
  }
  .main-header .menu-content .logo-content .arrow-outer {
    display: none;
  }
  .main-header .mobile-menu-btn {
    padding: 5px;
    width: 36px;
    height: 31px;
    display: block;
    transform: translateX(-5px);
    color: #029CE0;
    cursor: pointer;
  }
  .main-header .mobile-menu-btn .icon {
    width: 100%;
    height: 100%;
  }
  .main-header .main-menu li.site-item {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .main-header {
    padding-bottom: 86px;
  }
  .main-header.opened-fix-header .fix-content {
    height: 102px;
    top: -102px;
    transform: translateY(102px);
  }
  .main-header .profile-content .search-form {
    display: none;
  }
  .main-header .menu-content > .inner-content {
    height: 52px;
  }
  .main-header .menu-content .logo-content {
    width: 130px;
    transform: translateY(-5px);
  }
  .main-header .menu-content .tracking-button {
    min-height: 32px;
    padding: 6px 10px 6px 10px;
  }
}
@media screen and (max-width: 600px) {
  .main-header {
    padding-bottom: 48px;
  }
  .main-header.opened-fix-header .fix-content {
    height: 64px;
    top: -64px;
    transform: translateY(64px);
  }
  .main-header .hello-bar .inner-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    padding-right: 50px;
  }
  .main-header .hello-bar .inner-content .button {
    width: 64px;
    min-width: 64px;
  }
  .main-header .hello-bar .inner-content .text-content {
    width: calc(100% - 64px);
    padding-right: 15px;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
  }
  .main-header .profile-content {
    display: none;
  }
  .main-header .menu-content > .inner-content {
    height: 48px;
  }
  .main-header .menu-content .logo-content {
    width: 120px;
    transform: translateY(-5px);
  }
  .main-header .menu-content .main-menu {
    display: none;
  }
  .main-header .mobile-menu-btn {
    width: 32px;
    height: 27px;
  }
}
footer {
  color: #ffffff;
  background: #029CE0;
}
footer a {
  color: inherit;
  text-decoration: none;
}
footer .top-content {
  padding: 19px 0px 19px 0px;
  background-color: #0084D4;
  background-image: linear-gradient(110deg, #0084D4 50.2%, #029CE0 850%);
}
footer .footer-contact-item {
  position: relative;
  display: flex;
  align-items: center;
}
footer .footer-contact-item:after {
  content: "";
  width: 1px;
  height: 24px;
  min-width: 1px;
  background: #ffffff;
  margin: 0px 40px 0px 40px;
}
footer .footer-contact-item:last-of-type:after {
  display: none;
}
footer .footer-contact-link img {
  margin-right: 9px;
}
footer .footer-contact-link .text {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.4px;
  font-weight: 600;
}
footer .social-item {
  color: #ffffff;
  margin-right: 16px;
}
footer .social-item:last-of-type {
  margin-right: 0px;
}
footer .middle-content {
  padding: 50px 0px 50px 0px;
}
footer .middle-content .inner-content {
  gap: 30px;
}
footer .middle-content .title-row {
  margin-bottom: 18px;
  min-height: 44px;
}
footer .middle-content .title-row .icon {
  display: none;
}
footer .middle-content .row {
  margin-bottom: 10px;
  line-height: 18px;
}
footer .middle-content .row:last-of-type {
  margin-bottom: 0px;
}
footer .bottom-content {
  padding: 12px 0px 12px 0px;
}
footer .bottom-content .left-content .logo-content {
  width: 75px;
  margin-right: 25px;
}
footer .pwstudio-link .text {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
}
footer .pwstudio-link .icon {
  min-width: 16px;
  margin-left: 5px;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  footer a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
  footer .pwstudio-link:hover, footer .footer-contact-link:hover {
    text-decoration: none;
  }
  footer .pwstudio-link:hover .text, footer .footer-contact-link:hover .text {
    text-decoration: underline;
  }
}
@media (hover: hover) {
  footer a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
  footer .pwstudio-link:hover, footer .footer-contact-link:hover {
    text-decoration: none;
  }
  footer .pwstudio-link:hover .text, footer .footer-contact-link:hover .text {
    text-decoration: underline;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  footer a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
  footer .pwstudio-link:hover, footer .footer-contact-link:hover {
    text-decoration: none;
  }
  footer .pwstudio-link:hover .text, footer .footer-contact-link:hover .text {
    text-decoration: underline;
  }
}
@media screen and (max-width: 1023px) {
  footer .footer-contact-item:after {
    margin: 0px 20px 0px 20px;
  }
  footer .footer-contact-link .text {
    font-size: 14px;
    line-height: 20px;
  }
  footer .middle-content {
    padding: 0px 0px 30px 0px;
  }
  footer .middle-content > .inner-content {
    display: block;
  }
  footer .accordion-item {
    border-bottom: 1px solid #ffffff;
  }
  footer .accordion-item .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 18px 10px 18px 10px;
    cursor: pointer;
  }
  footer .accordion-item .title-row .icon {
    display: block;
    width: 8px;
    min-width: 8px;
    height: 5px;
  }
  footer .accordion-item .title-row .title {
    padding-right: 10px;
  }
  footer .accordion-item .list-content {
    display: none;
  }
  footer .accordion-item .list-content .list-inner {
    padding: 3px 10px 25px 10px;
    column-count: 3;
    column-gap: 30px;
    width: 100%;
  }
  footer .accordion-item .list-content .list-inner .row {
    break-inside: avoid;
  }
}
@media screen and (max-width: 767px) {
  footer .accordion-item .list-content .list-inner {
    column-count: 2;
  }
  footer .top-content {
    background: #029CE0;
    padding: 40px 0px 40px 0px;
  }
  footer .top-content > .inner-content {
    display: block;
  }
  footer .footer-contact-item {
    margin: 0px 0px 14px 0px;
  }
  footer .footer-contact-item:last-of-type {
    margin-bottom: 0px;
  }
  footer .footer-contact-item:after {
    display: none;
  }
  footer .footer-contact-item.social-items {
    margin-top: 30px;
  }
  footer .accordion-item:first-of-type {
    border-top: 1px solid #ffffff;
  }
}
@media screen and (max-width: 600px) {
  footer .accordion-item .title-row .title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
  }
  footer .accordion-item .list-content .list-inner {
    column-count: 1;
  }
  footer .accordion-item .list-content .row {
    font-size: 14px;
  }
  footer .middle-content {
    padding-bottom: 40px;
  }
  footer .bottom-content {
    padding-top: 0px;
  }
  footer .bottom-content .inner-content {
    align-items: flex-end;
  }
  footer .bottom-content .left-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  footer .bottom-content .left-content .logo-content {
    width: 66px;
    margin: 0px 0px 6px 0px;
  }
  footer .bottom-content .left-content .copyright {
    font-weight: 600;
  }
  footer .bottom-content .pwstudio-link .text {
    text-align: right;
  }
  footer .bottom-content .pwstudio-link .text span {
    display: block;
  }
}
.icon {
  display: block;
  font-size: 0px;
  line-height: 0px;
}

.profile-icon {
  width: 18px;
  height: 18px;
}

.tracking-icon {
  width: 18px;
  height: 19px;
}

.facebook-icon {
  width: 21px;
  height: 21px;
}

.twitter-icon {
  width: 21px;
  height: 18px;
}

.pwstudio-logo {
  width: 16px;
  height: 18px;
}

.calculator-document-icon {
  width: 16px;
  height: 21px;
}

.calculator-box-icon {
  width: 20px;
  height: 23px;
}

.calculator-pallet-icon {
  width: 25px;
  height: 19px;
}

.calculator-not-in-box-icon {
  width: 21px;
  height: 21px;
}

.trash-icon {
  width: 22px;
  height: 28px;
}

.package-tick-icon {
  width: 17px;
  height: 18px;
}

.bordered-info-icon {
  width: 20px;
  height: 21px;
}

.decoration-arrow-1 {
  width: 129px;
  height: 32px;
}

.decoration-arrow-2 {
  width: 99px;
  height: 60px;
}

.decoration-arrow-3 {
  width: 129px;
  height: 44px;
}

.choose-us-icon-1 {
  width: 43px;
  height: 26px;
}

.choose-us-icon-2 {
  width: 37px;
  height: 35px;
}

.choose-us-icon-3 {
  width: 47px;
  height: 44px;
}

.choose-us-icon-4 {
  width: 39px;
  height: 44px;
}

.calculator-right-arrow {
  width: 24px;
  height: 9px;
}

.arrow-right {
  width: 4px;
  height: 8px;
}

.tick-icon {
  width: 10px;
  height: 8px;
}

.printer-required-icon,
.printer-not-required-icon {
  width: 29px;
  height: 28px;
}

.cod-available-icon {
  width: 31px;
  height: 24px;
}

.transit-time-icon {
  width: 29px;
  height: 25px;
}

.package-pick-up-icon {
  width: 22px;
  height: 22px;
}

.info-icon {
  width: 22px;
  height: 22px;
}

.close-icon {
  width: 12px;
  height: 12px;
}

.arrow-left {
  width: 7px;
  height: 10px;
}

.arrow-down {
  width: 8px;
  height: 5px;
}

.search-icon {
  width: 18px;
  height: 17px;
}

.mobile-menu-icon {
  width: 22px;
  height: 17px;
}

.eye-icon {
  width: 22px;
  height: 15px;
}

.pdf-icon {
  width: 16px;
  height: 20px;
}

.pager-arrow-left,
.pager-arrow-right {
  width: 6px;
  height: 9px;
}

.button, button, input[type=button], input[type=submit] {
  font-family: "Catamaran", Arial, sans-serif;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: none;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: none;
  min-height: 48px;
  padding: 9px 18px 7px 18px;
  border: 1px solid #029CE0;
  border-radius: 2px;
  background-color: #029CE0;
  color: #ffffff;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}
.button.small, .button.small-button, button.small, button.small-button, input[type=button].small, input[type=button].small-button, input[type=submit].small, input[type=submit].small-button {
  min-height: 40px;
  padding: 9px 18px 7px 18px;
  font-size: 14px;
  line-height: 18px;
}
.button.mini, .button.mini-button, button.mini, button.mini-button, input[type=button].mini, input[type=button].mini-button, input[type=submit].mini, input[type=submit].mini-button {
  min-height: 26px;
  padding: 5px 10px 3px 10px;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}
.button.secondary, .button.secondary-button, button.secondary, button.secondary-button, input[type=button].secondary, input[type=button].secondary-button, input[type=submit].secondary, input[type=submit].secondary-button {
  color: #ffffff;
  background: #F46939;
  border-color: #F46939;
}
.button.empty, .button.empty-button, button.empty, button.empty-button, input[type=button].empty, input[type=button].empty-button, input[type=submit].empty, input[type=submit].empty-button {
  background: #ffffff;
  border-color: #029CE0;
  color: #029CE0;
}
.button.empty.secondary, .button.empty.secondary-button, .button.empty-button.secondary, .button.empty-button.secondary-button, button.empty.secondary, button.empty.secondary-button, button.empty-button.secondary, button.empty-button.secondary-button, input[type=button].empty.secondary, input[type=button].empty.secondary-button, input[type=button].empty-button.secondary, input[type=button].empty-button.secondary-button, input[type=submit].empty.secondary, input[type=submit].empty.secondary-button, input[type=submit].empty-button.secondary, input[type=submit].empty-button.secondary-button {
  background: #ffffff;
  color: #F46939;
  border-color: #F46939;
}

label {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  display: inline-flex;
}
label.required:after {
  content: "*";
  color: #EC2424;
}

.form-content .field-label {
  display: block;
  color: #393939;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.form-content .field-label.has-error {
  color: #EC2424;
}
.form-content .error-content {
  color: #EC2424;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-top: 2px;
}
.form-content .row {
  margin-bottom: 20px;
  font-size: 0px;
  line-height: 0px;
}
.form-content .row.checkbox-row {
  margin-bottom: 5px;
}
.form-content .row:last-of-type, .form-content .row.without-margin {
  margin-bottom: 0px;
}
.form-content .more-col-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.form-content .more-col-row .col {
  margin-top: 20px;
}
.form-content .more-col-row .col:not(.full-width) {
  width: calc(50% - (20px / 2));
}
.form-content .more-col-row .col:nth-of-type(1), .form-content .more-col-row .col:nth-of-type(2) {
  margin-top: 0px;
}
.form-content .more-col-row .break-row {
  width: 100%;
}
.form-content .button-row {
  margin-top: 30px;
}
.form-content .depends-on-row {
  display: none;
}
.form-content .depends-on-row:not(.without-padding) {
  padding-top: 20px;
}

.field, .form-control {
  appearance: none;
  outline: none;
  box-shadow: none;
  width: 100%;
  border: 1px solid #C0C4CB;
  border-radius: 2px;
  padding: 10px 12px 10px 12px;
  height: 48px;
  font-family: "Catamaran", Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  color: #393939;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}
.field.secondary:not(:invalid):not(.has-error), .field.secondary-field:not(:invalid):not(.has-error), .form-control.secondary:not(:invalid):not(.has-error), .form-control.secondary-field:not(:invalid):not(.has-error) {
  border-color: #C0C4CB;
}
.field.has-error, .form-control.has-error {
  color: #EC2424;
  background-color: #FFECEC;
  border-color: #EC2424;
}
.field:focus, .form-control:focus {
  color: #393939;
  background-color: #ffffff;
  border-color: #393939;
}

textarea.field, textarea.form-control {
  min-height: 115px;
  resize: none;
}

select {
  appearance: none;
  outline: none;
  box-shadow: none;
  width: 100%;
  display: block;
  border: 1px solid #C0C4CB;
  border-radius: 2px;
  padding: 0px 35px 0px 15px;
  height: 48px;
  font-family: "Catamaran", Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  color: #393939;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background: url("../img/svg/icons/select-arrow.svg") no-repeat #ffffff top 16px right 11px/10px 6px;
}
select:invalid, select.has-error {
  color: #EC2424;
  background-color: #FFECEC;
  border-color: #EC2424;
}
select.secondary:not(:invalid):not(.has-error) {
  border-color: #C0C4CB;
}

@-moz-document url-prefix() {
  select {
    text-indent: -2px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(71, 71, 71, 0.8);
}

:-moz-placeholder {
  opacity: 1;
  color: rgba(71, 71, 71, 0.8);
}

::-moz-placeholder {
  opacity: 1;
  color: rgba(71, 71, 71, 0.8);
}

:-ms-input-placeholder {
  opacity: 1;
  color: rgba(71, 71, 71, 0.8);
}

input[type=checkbox] {
  width: 0px;
  height: 0px;
  display: none;
}
input[type=checkbox] + label {
  padding-left: 26px;
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #393939;
  transition: color 0.25s ease;
  margin: 0px;
}
input[type=checkbox] + label a {
  color: #029CE0;
}
input[type=checkbox] + label:before, input[type=checkbox] + label:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
}
input[type=checkbox] + label:before {
  top: -1px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #C0C4CB;
  border-radius: 2px;
  transition: background-color 0.25s ease;
}
input[type=checkbox] + label:after {
  top: -1px;
  left: 0px;
  background: url("../img/svg/icons/check.svg") no-repeat #F46939 center center/10px 8px;
  transition: opacity 0.25s ease;
  opacity: 0;
  border-radius: 2px;
}
input[type=checkbox] + label.secondary:before {
  border-color: #029CE0;
}
input[type=checkbox] + label.secondary:after {
  background: url("../img/svg/icons/check.svg") no-repeat #029CE0 center center/10px 8px;
}
input[type=checkbox].small-checkbox + label {
  font-size: 12px;
  line-height: 20px;
  padding-left: 19px;
}
input[type=checkbox].small-checkbox + label:before, input[type=checkbox].small-checkbox + label:after {
  width: 12px;
  height: 12px;
  top: 4px;
}
input[type=checkbox].small-checkbox + label:after {
  background: url("../img/svg/icons/check.svg") no-repeat #F46939 center center/10px 8px;
}
input[type=checkbox].small-checkbox + label.secondary:after {
  background: url("../img/svg/icons/check.svg") no-repeat #029CE0 center center/10px 8px;
}
input[type=checkbox]:checked + label {
  color: #393939;
}
input[type=checkbox]:checked + label:after {
  opacity: 1;
}
input[type=checkbox].has-error:not(:checked) + label, input[type=checkbox].has-error:not(:checked) + label.secondary {
  color: #EC2424;
}
input[type=checkbox].has-error:not(:checked) + label a, input[type=checkbox].has-error:not(:checked) + label.secondary a {
  color: inherit;
}

input[type=radio] {
  width: 0px;
  height: 0px;
  display: none;
}
input[type=radio]:not(.button-radio) + label {
  position: relative;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  padding-left: 28px;
  display: inline-block;
  transition: none;
}
input[type=radio]:not(.button-radio) + label:before, input[type=radio]:not(.button-radio) + label:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
input[type=radio]:not(.button-radio) + label:before {
  border: 1px solid #C0C4CB;
  background: #ffffff;
  transition: background-color 0.25s ease;
  width: 20px;
  height: 20px;
  top: -1px;
  left: 0px;
}
input[type=radio]:not(.button-radio) + label:after {
  width: 10px;
  height: 10px;
  background: #F46939;
  top: 4px;
  left: 5px;
  opacity: 0;
  transition: opacity 0.25s ease;
}
input[type=radio]:not(.button-radio):checked + label {
  color: #393939;
}
input[type=radio]:not(.button-radio):checked + label:before {
  background: #ffffff;
  border-color: #F46939;
}
input[type=radio]:not(.button-radio):checked + label:after {
  background: #F46939;
  opacity: 1;
}
input[type=radio]:not(.button-radio).has-error:not(:checked) + label:before {
  background-color: #FFECEC;
  border-color: #EC2424;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  input[type=checkbox] + label:before, input[type=checkbox] + label:after,
input[type=radio] + label:before,
input[type=radio] + label:after {
    transform: translateY(1px);
  }
}
@media all and (min--moz-device-pixel-ratio: 0) {
  .button:hover, button:hover, input[type=button]:hover, input[type=submit]:hover {
    color: #029CE0;
    background: #ffffff;
    border-color: #029CE0;
  }
  .button:hover.secondary, .button:hover.secondary-button, button:hover.secondary, button:hover.secondary-button, input[type=button]:hover.secondary, input[type=button]:hover.secondary-button, input[type=submit]:hover.secondary, input[type=submit]:hover.secondary-button {
    color: #F46939;
    background: #ffffff;
    border-color: #F46939;
  }
  .button:hover.empty, .button:hover.empty-button, button:hover.empty, button:hover.empty-button, input[type=button]:hover.empty, input[type=button]:hover.empty-button, input[type=submit]:hover.empty, input[type=submit]:hover.empty-button {
    color: #ffffff;
    background: #029CE0;
    border-color: #029CE0;
  }
  .button:hover.empty.secondary, .button:hover.empty.secondary-button, .button:hover.empty-button.secondary, .button:hover.empty-button.secondary-button, button:hover.empty.secondary, button:hover.empty.secondary-button, button:hover.empty-button.secondary, button:hover.empty-button.secondary-button, input[type=button]:hover.empty.secondary, input[type=button]:hover.empty.secondary-button, input[type=button]:hover.empty-button.secondary, input[type=button]:hover.empty-button.secondary-button, input[type=submit]:hover.empty.secondary, input[type=submit]:hover.empty.secondary-button, input[type=submit]:hover.empty-button.secondary, input[type=submit]:hover.empty-button.secondary-button {
    color: #ffffff;
    background: #F46939;
    border-color: #F46939;
  }

  input[type=checkbox] + label a:hover {
    color: #029CE0;
  }
}
@media (hover: hover) {
  .button:hover, button:hover, input[type=button]:hover, input[type=submit]:hover {
    color: #029CE0;
    background: #ffffff;
    border-color: #029CE0;
  }
  .button:hover.secondary, .button:hover.secondary-button, button:hover.secondary, button:hover.secondary-button, input[type=button]:hover.secondary, input[type=button]:hover.secondary-button, input[type=submit]:hover.secondary, input[type=submit]:hover.secondary-button {
    color: #F46939;
    background: #ffffff;
    border-color: #F46939;
  }
  .button:hover.empty, .button:hover.empty-button, button:hover.empty, button:hover.empty-button, input[type=button]:hover.empty, input[type=button]:hover.empty-button, input[type=submit]:hover.empty, input[type=submit]:hover.empty-button {
    color: #ffffff;
    background: #029CE0;
    border-color: #029CE0;
  }
  .button:hover.empty.secondary, .button:hover.empty.secondary-button, .button:hover.empty-button.secondary, .button:hover.empty-button.secondary-button, button:hover.empty.secondary, button:hover.empty.secondary-button, button:hover.empty-button.secondary, button:hover.empty-button.secondary-button, input[type=button]:hover.empty.secondary, input[type=button]:hover.empty.secondary-button, input[type=button]:hover.empty-button.secondary, input[type=button]:hover.empty-button.secondary-button, input[type=submit]:hover.empty.secondary, input[type=submit]:hover.empty.secondary-button, input[type=submit]:hover.empty-button.secondary, input[type=submit]:hover.empty-button.secondary-button {
    color: #ffffff;
    background: #F46939;
    border-color: #F46939;
  }

  input[type=checkbox] + label a:hover {
    color: #029CE0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .button:hover, button:hover, input[type=button]:hover, input[type=submit]:hover {
    color: #029CE0;
    background: #ffffff;
    border-color: #029CE0;
  }
  .button:hover.secondary, .button:hover.secondary-button, button:hover.secondary, button:hover.secondary-button, input[type=button]:hover.secondary, input[type=button]:hover.secondary-button, input[type=submit]:hover.secondary, input[type=submit]:hover.secondary-button {
    color: #F46939;
    background: #ffffff;
    border-color: #F46939;
  }
  .button:hover.empty, .button:hover.empty-button, button:hover.empty, button:hover.empty-button, input[type=button]:hover.empty, input[type=button]:hover.empty-button, input[type=submit]:hover.empty, input[type=submit]:hover.empty-button {
    color: #ffffff;
    background: #029CE0;
    border-color: #029CE0;
  }
  .button:hover.empty.secondary, .button:hover.empty.secondary-button, .button:hover.empty-button.secondary, .button:hover.empty-button.secondary-button, button:hover.empty.secondary, button:hover.empty.secondary-button, button:hover.empty-button.secondary, button:hover.empty-button.secondary-button, input[type=button]:hover.empty.secondary, input[type=button]:hover.empty.secondary-button, input[type=button]:hover.empty-button.secondary, input[type=button]:hover.empty-button.secondary-button, input[type=submit]:hover.empty.secondary, input[type=submit]:hover.empty.secondary-button, input[type=submit]:hover.empty-button.secondary, input[type=submit]:hover.empty-button.secondary-button {
    color: #ffffff;
    background: #F46939;
    border-color: #F46939;
  }

  input[type=checkbox] + label a:hover {
    color: #029CE0;
  }
}
@media screen and (max-width: 600px) {
  .form-content .row {
    margin-bottom: 15px;
  }
  .form-content .more-col-row .col {
    margin-top: 15px;
  }
  .form-content .more-col-row .col:not(.full-width) {
    width: 100%;
  }
  .form-content .more-col-row .col:nth-of-type(2) {
    margin-top: 15px;
  }
}
.calculator-container {
  text-align: left;
}
.calculator-container .selectric-wrapper {
  height: 56px;
}
.calculator-container .calculator-tabs {
  gap: 0px 10px;
}
.calculator-container .calculator-tabs .calculator-document-icon {
  min-width: 16px;
}
.calculator-container .calculator-tabs .calculator-box-icon {
  min-width: 20px;
}
.calculator-container .calculator-tabs .calculator-pallet-icon {
  min-width: 25px;
}
.calculator-container .calculator-tabs .calculator-not-in-box-icon {
  min-width: 21px;
}
.calculator-container .calculator-tabs .calculator-tab-item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 0px;
  line-height: 0px;
  color: #ffffff;
  height: 67px;
  cursor: pointer;
}
.calculator-container .calculator-tabs .calculator-tab-item .inner {
  background: rgba(204, 204, 204, 0.6);
  border: 0px solid #F46939;
  padding: 10px 24px 10px 24px;
  border-radius: 2px 2px 0px 0px;
  height: 59px;
  transition: background-color 0.25s ease;
}
.calculator-container .calculator-tabs .calculator-tab-item .text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  transition: color 0.25s ease;
}
.calculator-container .calculator-tabs .calculator-tab-item .icon {
  margin-right: 9px;
  transition: color 0.25s ease;
}
.calculator-container .calculator-tabs .calculator-tab-item.active {
  color: #393939;
}
.calculator-container .calculator-tabs .calculator-tab-item.active .icon {
  color: #F46939;
}
.calculator-container .calculator-tabs .calculator-tab-item.active .inner {
  border-top-width: 8px;
  background: rgba(255, 255, 255, 0.86);
  height: 67px;
}
.calculator-container .calculator-content {
  padding: 30px 0px 30px 0px;
}
.calculator-container .calculator-content > .close-btn {
  display: none;
  top: 10px;
  right: 10px;
}
.calculator-container .calculator-inner {
  width: calc(100% - 80px);
  margin: auto;
  position: relative;
}
.calculator-container .calculator-inner .button {
  min-height: 48px;
}
.calculator-container .title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 18px;
}
.calculator-container .add-row-link {
  text-decoration: none;
}
.calculator-container .add-row-link .icon {
  margin-right: 7px;
}
.calculator-container .add-row-link .text {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.4px;
  transform: translateY(1px);
}
.calculator-container label {
  font-weight: 700;
  margin-bottom: 2px;
  display: block;
  letter-spacing: 0.4px;
}
.calculator-container label.big-label {
  font-size: 18px;
  line-height: 20px;
}
.calculator-container .address-content {
  margin-bottom: 26px;
}
.calculator-container .address-content select, .calculator-container .address-content .field {
  height: 56px;
}
.calculator-container .address-content .address-box {
  width: calc((100% - 56px) / 2);
}
.calculator-container .address-content .arrow-box {
  width: 56px;
  height: 56px;
  align-self: flex-end;
}
.calculator-container .address-content .zip-outer {
  width: 70px;
}
.calculator-container .address-content .country-outer {
  width: calc(100% - 80px);
}
.calculator-container .packages-content {
  margin-bottom: 25px;
}
.calculator-container .package-row {
  gap: 10px;
  grid-template-columns: 195px repeat(4, calc((100% - 261px) / 4)) 22px;
  margin-bottom: 25px;
}
.calculator-container .package-row:last-of-type {
  margin-bottom: 0px;
}
.calculator-container .package-row label {
  font-size: 14px;
  line-height: 16px;
}
.calculator-container .package-row .counter {
  width: 36px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 2px;
  background: #BEE3F4;
  margin-right: 10px;
}
.calculator-container .package-row .counter .inner {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.calculator-container .package-row .counter .inner .hash {
  font-size: 14px;
  line-height: 14px;
}
.calculator-container .package-row .counter .inner .number {
  font-size: 16px;
  line-height: 16px;
}
.calculator-container .package-row .counter-col {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.calculator-container .package-row .counter-col > label {
  width: 100%;
}
.calculator-container .package-row .counter-col .field-container {
  width: calc(100% - 46px);
  max-width: 120px;
}
.calculator-container .package-row .field-container {
  position: relative;
}
.calculator-container .package-row .field-container label {
  font-weight: 400;
  padding: 0px 0px 0px 0px;
  transform: translateY(1px);
  position: absolute;
  height: 16px;
  top: 0px;
  bottom: 0px;
  right: 10px;
  margin: auto;
}
.calculator-container .package-row .field-container .field {
  padding-right: 35px;
}
.calculator-container .package-row .delete-col {
  height: 48px;
  align-self: end;
  width: 16px;
}
.calculator-container .package-row .delete-col a {
  position: relative;
  display: block;
  width: 16px;
  height: 21px;
  cursor: pointe;
}
.calculator-container .package-row .delete-col a .icon {
  width: 100%;
  height: 100%;
}
.calculator-container .package-row .delete-col a:before {
  content: "";
  position: absolute;
  display: block;
  top: 0px;
  bottom: 0px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  transform: translate(-4px, -4px);
}
.calculator-container .submit-row {
  margin-top: 10px;
}
.calculator-container .submit-row .info {
  padding-right: 15px;
}
.calculator-container .submit-row .info .icon {
  min-width: 20px;
  margin-right: 6px;
}
.calculator-container .submit-row .info .text {
  font-size: 14px;
  line-height: 20px;
}
.calculator-container .single-form-submit-row {
  position: absolute;
  right: 0px;
  bottom: 49px;
}
.calculator-container .field-container {
  position: relative;
}
.calculator-container .field-container .error-content {
  position: absolute;
  top: calc(100% + 2px);
  color: #EC2424;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.calculator-container .calculator-panel {
  margin-bottom: 25px;
}
.calculator-container .calculator-panel-inner {
  padding: 14px 15px 14px 10px;
  border-radius: 2px;
  background: #F7F7F7;
  width: 100%;
  max-width: 370px;
}
.calculator-container .calculator-panel-inner .send-button {
  display: none;
}
.calculator-container .calculator-panel-inner .left-content {
  padding-right: 15px;
}
.calculator-container .calculator-panel-inner .icon {
  color: #029CE0;
}
.calculator-container .calculator-panel-inner .package-infos {
  font-size: 0px;
  line-height: 0px;
  padding-left: 10px;
}
.calculator-container .calculator-panel-inner .package-infos .label {
  font-size: 12px;
  line-height: 16px;
}
.calculator-container .calculator-panel-inner .package-infos .row {
  margin-bottom: 5px;
}
.calculator-container .calculator-panel-inner .package-infos .row:last-of-type {
  margin-bottom: 0px;
}
.calculator-container .calculator-panel-inner .package-infos .info {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.calculator-container .calculator-panel-inner .package-infos .info:after {
  content: "";
  width: 1px;
  height: 20px;
  background: #757982;
  margin: 0px 9px 0px 9px;
}
.calculator-container .calculator-panel-inner .package-infos .info:last-of-type:after {
  display: none;
}
.calculator-container .calculator-panel-inner .package-infos .infos.mobile-infos {
  display: none;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .calculator-container .add-row-link:hover .text {
    text-decoration: underline;
  }
  .calculator-container .delete-col a:hover {
    color: #F46939;
  }
}
@media (hover: hover) {
  .calculator-container .add-row-link:hover .text {
    text-decoration: underline;
  }
  .calculator-container .delete-col a:hover {
    color: #F46939;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .calculator-container .add-row-link:hover .text {
    text-decoration: underline;
  }
  .calculator-container .delete-col a:hover {
    color: #F46939;
  }
}
@media screen and (max-width: 1100px) {
  .calculator-container .single-form-submit-row {
    bottom: 0px;
    transform: translate(20px, 10px);
  }
}
@media screen and (max-width: 1023px) {
  .calculator-container .calculator-tabs .calculator-tab-item {
    height: 61px;
  }
  .calculator-container .calculator-tabs .calculator-tab-item .icon {
    display: none;
  }
  .calculator-container .calculator-tabs .calculator-tab-item .inner {
    padding: 7px 15px 7px 15px;
    height: 53px;
  }
  .calculator-container .calculator-tabs .calculator-tab-item .text {
    text-align: center;
  }
  .calculator-container .calculator-tabs .calculator-tab-item.active .inner {
    height: 61px;
  }
  .calculator-container .calculator-inner {
    width: calc(100% - 60px);
  }
  .calculator-container .submit-row {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .calculator-container .submit-row .info {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .calculator-container .submit-row .button {
    align-self: flex-end;
  }
  .calculator-container .package-row {
    grid-template-columns: 150px repeat(4, calc((100% - 222px) / 4)) 22px;
  }
  .calculator-container .single-form-submit-row {
    bottom: 0px;
    transform: translate(10px, 10px);
  }
  .calculator-container.has-panel {
    margin-bottom: 0px !important;
  }
  .calculator-container.has-panel .calculator-panel {
    z-index: 100;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 54px;
    margin: 0px;
    transition: transform 0.25s ease;
  }
  .calculator-container.has-panel .calculator-panel.inactive {
    transform: translateY(calc(100% + 10px));
  }
  .calculator-container.has-panel .calculator-panel .calculator-panel-inner {
    width: 100%;
    max-width: none;
    background: #F7F7F7;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 14px 30px;
  }
  .calculator-container.has-panel .calculator-content {
    position: fixed;
    border-radius: 5px 5px 0px 0px;
    display: block !important;
    width: 100%;
    overflow: hidden;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
    transition: transform 0.25s ease;
    transform: translateY(calc(100% + 50px));
    background: #F7F7F7;
    box-shadow: 0 -1px 40px 0 rgba(0, 0, 0, 0.36);
    height: auto;
    max-height: calc(78vh - 100px);
    padding-bottom: 60px;
  }
  .calculator-container.has-panel .calculator-content > .close-btn {
    display: block;
  }
  .calculator-container.has-panel .calculator-content .calculator-inner {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: calc(78vh - 190px);
  }
  .calculator-container.has-panel .calculator-content .calculator-inner:after {
    content: "";
    height: 40px;
    width: 1px;
    display: block;
    min-width: 1px;
    min-height: 40px;
  }
  .calculator-container.has-panel .calculator-content .single-form-submit-row {
    display: none;
  }
  .calculator-container.has-panel .calculator-content .packages-content, .calculator-container.has-panel .calculator-content .address-content {
    max-width: none;
  }
  .calculator-container.has-panel .calculator-panel .calculator-panel-inner .package-infos .infos.desktop-infos {
    display: none;
  }
  .calculator-container.has-panel .calculator-panel .calculator-panel-inner .package-infos .infos.mobile-infos {
    display: block;
    font-size: 12px;
    line-height: 16px;
  }
  .calculator-container.has-panel.opened-calculator .calculator-content {
    transform: translateY(0px);
  }
  .calculator-container.has-panel.opened-calculator .calculator-panel-inner .panel-button {
    display: none;
  }
  .calculator-container.has-panel.opened-calculator .calculator-panel-inner .send-button {
    display: flex;
  }

  .opened-fix-header .calculator-container.has-panel .calculator-content {
    max-height: calc(78vh - 50px);
  }
  .opened-fix-header .calculator-container.has-panel .calculator-content .calculator-inner {
    max-height: calc(78vh - 140px);
  }
}
@media screen and (max-width: 767px) {
  .calculator-container .package-row {
    grid-template-columns: repeat(2, calc(50% - 5px));
    gap: 20px 10px;
    margin-bottom: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid #C0C4CB;
  }
  .calculator-container .package-row:last-of-type {
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .calculator-container .package-row .counter-col .field-container {
    max-width: none;
  }
  .calculator-container .package-row .delete-col {
    justify-content: flex-end;
    width: 100%;
  }
  .calculator-container .submit-row {
    margin-top: 25px;
  }
  .calculator-container .submit-row .info {
    margin-bottom: 15px;
  }
  .calculator-container .submit-row .button {
    align-self: center;
    width: 100%;
    max-width: 400px;
  }
  .calculator-container .address-content {
    flex-wrap: wrap;
  }
  .calculator-container .address-content .arrow-box {
    display: none;
  }
  .calculator-container .address-content .address-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .calculator-container .address-content .address-box:last-of-type {
    margin-bottom: 0px;
  }
  .calculator-container .address-content .zip-outer {
    width: 80px;
  }
  .calculator-container .address-content .country-outer {
    width: calc(100% - 90px);
  }
}
@media screen and (max-width: 600px) {
  .calculator-container .title {
    margin-bottom: 14px;
    font-size: 20px;
  }
  .calculator-container .calculator-content {
    padding-bottom: 40px;
  }
  .calculator-container .selectric-wrapper, .calculator-container .field, .calculator-container .address-content .field {
    height: 48px;
  }
  .calculator-container label.big-label {
    font-size: 14px;
    line-height: 16px;
  }
  .calculator-container .calculator-tabs {
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .calculator-container .calculator-tabs .calculator-tab-item {
    height: 52px;
  }
  .calculator-container .calculator-tabs .calculator-tab-item .icon {
    display: none;
  }
  .calculator-container .calculator-tabs .calculator-tab-item .inner {
    padding: 7px 10px 7px 10px;
    height: 44px;
  }
  .calculator-container .calculator-tabs .calculator-tab-item .text {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
  }
  .calculator-container .calculator-tabs .calculator-tab-item.active .inner {
    height: 52px;
  }
  .calculator-container .calculator-inner {
    width: calc(100% - (15px * 2));
  }
  .calculator-container .submit-row .button {
    max-width: none;
  }
  .calculator-container .package-row .delete-col a {
    width: 32px;
    height: 38px;
    padding: 5px;
    transform: translateX(5px);
  }
}
.package-calculator-section {
  position: relative;
  padding: 55px 0px 70px 0px;
}
.package-calculator-section > .content-width {
  position: static;
}
.package-calculator-section .background-item {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
.package-calculator-section .background-item img {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.package-calculator-section .top-text-content {
  color: #ffffff;
}
.package-calculator-section .top-text-content > *:not(.background-item) {
  position: relative;
  z-index: 2;
}
.package-calculator-section .top-text-content .compare-info-text {
  display: none;
}
.package-calculator-section h1, .package-calculator-section .like-h1 {
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}
.package-calculator-section .lead {
  margin-top: 10px;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.package-calculator-section .calculator-container {
  position: relative;
  z-index: 2;
  margin: 40px auto 0px auto;
  max-width: 910px;
}
.package-calculator-section .calculator-container .calculator-content {
  background: rgba(255, 255, 255, 0.86);
}
.package-calculator-section .calculator-container .calculator-inner {
  max-width: 740px;
}
.package-calculator-section .compare-info-text {
  position: relative;
  z-index: 2;
  margin: 30px auto 0px auto;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 12px 30px 12px 30px;
  border-radius: 80px;
}
.package-calculator-section .compare-info-text .icon {
  margin-right: 5px;
}
.package-calculator-section .compare-info-text .text {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0.4px;
  transform: translateY(1px);
}

@media screen and (max-width: 767px) {
  .package-calculator-section {
    padding: 0px;
    box-shadow: 0 1px 25px -13px rgba(43, 60, 68, 0.6);
  }
  .package-calculator-section .content-width {
    padding: 0px;
  }
  .package-calculator-section .content-width > *:not(.calculator-container)   {
    padding-left: 30px;
    padding-right: 30px;
  }
  .package-calculator-section .content-width > .compare-info-text {
    display: none;
  }
  .package-calculator-section .top-text-content {
    position: relative;
    padding: 40px 30px 30px 30px;
  }
  .package-calculator-section .top-text-content .compare-info-text {
    display: inline-flex;
  }
  .package-calculator-section .top-text-content .background-item {
    height: calc(100% + 61px);
  }
  .package-calculator-section .calculator-container {
    margin-top: 0px;
  }
  .package-calculator-section .calculator-container .calculator-tabs .calculator-tab-item.active .inner {
    background: #F7F7F7;
  }
  .package-calculator-section .calculator-container .calculator-content {
    background: #F7F7F7;
  }
}
@media screen and (max-width: 600px) {
  .package-calculator-section .top-text-content {
    padding: 40px 15px 30px 15px;
  }
  .package-calculator-section .top-text-content .background-item {
    height: calc(100% + 52px);
  }
  .package-calculator-section h1, .package-calculator-section .like-h1 {
    font-size: 22px;
    line-height: 26px;
  }
  .package-calculator-section .lead {
    font-size: 18px;
    line-height: 24px;
    margin-top: 0px;
  }
  .package-calculator-section .compare-info-text {
    margin-top: 24px;
    padding: 6px 8px 6px 8px;
  }
  .package-calculator-section .compare-info-text .text {
    font-size: 14px;
    line-height: 20px;
  }
}
/*======================================
  Selectric v1.13.0
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 12px;
  line-height: 38px;
  color: #444;
  height: 38px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #F8f8f8;
  color: #BBB;
  text-align: center;
  font: 0/0 a;
  *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}

.selectric-hover .selectric .button {
  color: #a2a2a2;
}

.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #c4c4c4;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 10px;
  color: #666;
  cursor: pointer;
}

.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}

.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}

.selectric-items li:hover {
  background: #D5D5D5;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

.selectric-wrapper {
  height: 48px;
}
.selectric-wrapper .selectric {
  height: 100%;
  background: #ffffff;
  border-color: #C0C4CB;
  transition: border-color 0.25s ease;
  border-radius: 2px;
}
.selectric-wrapper .selectric .label {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0px;
  line-height: 0px;
  color: #393939;
  margin: 0px 40px 0px 14px;
}
.selectric-wrapper .selectric .label .text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.selectric-wrapper .selectric .button {
  font-size: 0px;
  line-height: 0px;
  border: none;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #ffffff;
}
.selectric-wrapper .selectric .button:after {
  border: none;
  position: relative;
  width: 10px;
  height: 6px;
  background: url("../img/svg/icons/select-arrow.svg") no-repeat center center/cover;
}
.selectric-wrapper.selectric-open {
  z-index: 10;
}
.selectric-wrapper.selectric-open .selectric {
  border-color: #393939;
  border-radius: 2px 2px 0px 0px;
}
.selectric-wrapper .country-select-item {
  display: flex;
  align-items: center;
}
.selectric-wrapper .country-select-item .flag {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 18px;
  min-height: 18px;
}
.selectric-wrapper.selectric-above .selectric-items {
  border-top: 1px solid #393939;
  border-radius: 2px 2px 0px 0px;
  border-bottom: none;
}
.selectric-wrapper.selectric-above.selectric-open .selectric {
  border-radius: 0px 0px 2px 2px;
}

.selectric-items {
  background: #ffffff;
  border: 1px solid #393939;
  border-top: none;
  border-radius: 0px 0px 2px 2px;
}
.selectric-items li {
  background: #ffffff;
  color: #393939 !important;
  font-size: 14px;
  line-height: 18px;
  padding: 12px 10px 12px 10px;
}
.selectric-items li:hover {
  background: #F7F7F7;
}
.selectric-items li.highlighted {
  background: #D3EAF5;
}
.selectric-items .selectric-scroll ul {
  margin: 0px;
  padding: 0px;
}
.selectric-items .selectric-scroll ul li {
  margin: 0px;
}
.selectric-items .selectric-scroll ul li:before {
  display: none;
}

body.opened-mobile-menu {
  overflow: hidden;
}
body.opened-mobile-menu .mobile-menu-overlay {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-overlay {
  background: rgba(57, 57, 57, 0.34);
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  position: fixed;
  z-index: 108;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease;
  transition-delay: 150ms;
}

.mobile-menu-content {
  display: block;
  z-index: 109;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 360px;
  background: #ffffff;
  color: #393939;
  transition: transform 0.25s ease;
  padding: 0px 0px 50px 0px;
  box-sizing: border-box;
  position: fixed;
  overflow: hidden;
  transform: translateX(-360px);
}
.mobile-menu-content .move {
  transition: none !important;
}
.mobile-menu-content.opened {
  visibility: visible;
  opacity: 1;
  transform: translateX(0px);
}
.mobile-menu-content.animated {
  z-index: 109;
}
.mobile-menu-content a {
  text-decoration: none;
  color: #393939;
  font-weight: 400;
}
.mobile-menu-content .close-btn {
  width: 24px;
  height: 24px;
  top: 15px;
  right: 25px;
  color: #029CE0;
  z-index: 5;
  opacity: 1;
}
.mobile-menu-content .mobile-menu-group {
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
}
.mobile-menu-content .mobile-menu-group:first-of-type {
  margin-top: 52px;
}
.mobile-menu-content .mobile-menu-group.profile-group {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mobile-menu-content .mobile-menu-group.sites-group {
  margin-top: 20px;
}
.mobile-menu-content .mobile-menu-group.tracking-group {
  margin-top: 25px;
}
.mobile-menu-content .separator {
  width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
  background: #EBE4E2;
  height: 2px;
}
.mobile-menu-content .search-form {
  margin: 0px auto 15px auto;
  width: 100%;
  background: #F7F7F7;
}
.mobile-menu-content .mobile-menu-inner {
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.mobile-menu-content .mobile-menu-inner:after {
  content: "";
  height: 50px;
  min-height: 50px;
  display: block;
  width: 100%;
}
.mobile-menu-content .first-level-item {
  padding: 5px 0px 5px 0px;
  font-weight: 700;
}
.mobile-menu-content .first-level-item .text {
  font-size: 16px;
  line-height: 20px;
  padding-right: 15px;
}
.mobile-menu-content .first-level-item .icon {
  color: #029CE0;
  transform: rotate(-90deg);
  min-width: 8px;
  min-height: 5px;
}
.mobile-menu-content .profile-item {
  color: #757982;
  font-size: 0px;
  line-height: 0px;
  padding: 5px 0px 5px 0px;
}
.mobile-menu-content .profile-item .profile-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 5px;
}
.mobile-menu-content .profile-item .text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}
.mobile-menu-content .profile-parent-item .profile-icon {
  transform: none;
}
.mobile-menu-content .profile-parent-item .text {
  font-weight: 400;
  color: #393939;
  font-size: 14px;
  line-height: 18px;
  transform: translateY(2px);
  transition: color 0.25s ease;
}
.mobile-menu-content .menu-item-outer {
  margin-bottom: 10px;
}
.mobile-menu-content .menu-item-outer:last-of-type {
  margin-bottom: 0px;
}
.mobile-menu-content .menu-item-outer.opened-submenu .mobile-submenu {
  transform: translateX(0px);
}
.mobile-menu-content .tracking-button {
  width: 100%;
}
.mobile-menu-content .tracking-button .text {
  font-weight: 700;
}
.mobile-menu-content .social-group {
  margin-top: 40px;
}
.mobile-menu-content .social-group .social-item {
  margin-right: 15px;
  color: #029CE0;
}
.mobile-menu-content .social-group .social-item:last-of-type {
  margin-right: 0px;
}
.mobile-menu-content .mobile-submenu {
  transition: transform 0.25s ease;
  transform: translateX(-360px);
  width: 360px;
  height: 100vh;
  position: fixed;
  background: #ffffff;
  left: 0px;
  top: 0px;
  z-index: 10;
  overflow: hidden;
  padding-top: 52px;
}
.mobile-menu-content .mobile-submenu .separator {
  width: calc(100% + 10px);
  margin-bottom: 10px;
  transform: translateX(-5px);
}
.mobile-menu-content .mobile-submenu .mobile-menu-header {
  height: 52px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 30px;
  color: #029CE0;
}
.mobile-menu-content .mobile-submenu .mobile-menu-header .text {
  font-weight: 700;
  padding-left: 5px;
}
.mobile-menu-content .mobile-submenu .mobile-menu-header:after {
  content: "";
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  display: block;
  width: calc(100% - 50px);
  height: 2px;
  background: #EBE4E2;
}
.mobile-menu-content .mobile-submenu .submenu-inner {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 15px 30px 20px 30px;
}
.mobile-menu-content .mobile-submenu .submenu-inner .submenu-link {
  display: inline-flex;
  padding: 5px 0px 5px 0px;
}
.mobile-menu-content .mobile-submenu .submenu-inner .submenu-row {
  margin-bottom: 10px;
}
.mobile-menu-content .mobile-submenu .submenu-inner .submenu-row:last-of-type {
  margin-bottom: 0px;
}
.mobile-menu-content .logout-link {
  color: #757982;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .mobile-menu-content a:hover {
    text-decoration: none;
    color: #029CE0;
  }
  .mobile-menu-content a:hover.profile-item, .mobile-menu-content a:hover.logout-link {
    color: #393939;
  }
  .mobile-menu-content a:hover.profile-parent-item .text {
    color: #029CE0;
  }
}
@media (hover: hover) {
  .mobile-menu-content a:hover {
    text-decoration: none;
    color: #029CE0;
  }
  .mobile-menu-content a:hover.profile-item, .mobile-menu-content a:hover.logout-link {
    color: #393939;
  }
  .mobile-menu-content a:hover.profile-parent-item .text {
    color: #029CE0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mobile-menu-content a:hover {
    text-decoration: none;
    color: #029CE0;
  }
  .mobile-menu-content a:hover.profile-item, .mobile-menu-content a:hover.logout-link {
    color: #393939;
  }
  .mobile-menu-content a:hover.profile-parent-item .text {
    color: #029CE0;
  }
}
@media screen and (max-width: 600px) {
  .mobile-menu-content {
    width: 100vw;
    transform: translateX(-110%);
  }
  .mobile-menu-content .mobile-submenu {
    width: 100vw;
    transform: translateX(-110%);
  }
}
/* ==========================================================================
   Remodal's default mobile first theme
   ========================================================================== */
/* Default theme styles for the background */
.remodal-bg.remodal-is-opening,
.remodal-bg.remodal-is-opened {
  filter: blur(3px);
}

/* Default theme styles of the overlay */
.remodal-overlay {
  background: rgba(32, 32, 32, 0.34);
}

.remodal-overlay.remodal-is-opening,
.remodal-overlay.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal-overlay.remodal-is-opening {
  animation-name: remodal-overlay-opening-keyframes;
}

.remodal-overlay.remodal-is-closing {
  animation-name: remodal-overlay-closing-keyframes;
}

/* Default theme styles of the wrapper */
.remodal-wrapper {
  padding: 10px 10px 0;
}

/* Default theme styles of the modal dialog */
.remodal {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 35px;
  transform: translate3d(0, 0, 0);
  background: #FFFFFF;
  box-shadow: 0 2px 20px 4px rgba(68, 68, 68, 0.4);
}

.remodal.remodal-is-opening,
.remodal.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal.remodal-is-opening {
  animation-name: remodal-opening-keyframes;
}

.remodal.remodal-is-closing {
  animation-name: remodal-closing-keyframes;
}

/* Vertical align of the modal dialog */
.remodal,
.remodal-wrapper:after {
  vertical-align: middle;
}

/* Close button */
.remodal-close {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: visible;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;
  color: #95979c;
  border: 0;
  outline: 0;
  background: transparent;
}

.remodal-close:hover,
.remodal-close:focus {
  color: #2b2e38;
}

.remodal-close:before {
  font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
  font-size: 25px;
  line-height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 35px;
  content: "×";
  text-align: center;
}

/* Dialog buttons */
.remodal-confirm,
.remodal-cancel {
  font: inherit;
  display: inline-block;
  overflow: visible;
  min-width: 110px;
  margin: 0;
  padding: 12px 0;
  cursor: pointer;
  transition: background 0.2s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  border: 0;
  outline: 0;
}

.remodal-confirm {
  color: #fff;
  background: #81c784;
}

.remodal-confirm:hover,
.remodal-confirm:focus {
  background: #66bb6a;
}

.remodal-cancel {
  color: #fff;
  background: #e57373;
}

.remodal-cancel:hover,
.remodal-cancel:focus {
  background: #ef5350;
}

/* Remove inner padding and border in Firefox 4+ for the button tag. */
.remodal-confirm::-moz-focus-inner,
.remodal-cancel::-moz-focus-inner,
.remodal-close::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* Keyframes
   ========================================================================== */
@keyframes remodal-opening-keyframes {
  from {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes remodal-closing-keyframes {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.95);
    opacity: 0;
    filter: blur(0);
  }
}
@keyframes remodal-overlay-opening-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes remodal-overlay-closing-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Media queries
   ========================================================================== */
@media only screen and (min-width: 641px) {
  .remodal {
    max-width: 700px;
  }
}
/* IE8
   ========================================================================== */
.lt-ie9 .remodal-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodal {
  width: 700px;
}

/* ==========================================================================
   Remodal's necessary styles
   ========================================================================== */
/* Hide scroll bar */
html.remodal-is-locked {
  overflow: hidden;
  touch-action: none;
}

/* Anti FOUC */
.remodal,
[data-remodal-id] {
  display: none;
}

/* Necessary styles of the overlay */
.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: none;
}

/* Necessary styles of the wrapper */
.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}

.remodal-wrapper:after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: "";
}

/* Fix iPad, iPhone glitches */
.remodal-overlay,
.remodal-wrapper {
  backface-visibility: hidden;
}

/* Necessary styles of the modal dialog */
.remodal {
  position: relative;
  outline: none;
  text-size-adjust: 100%;
}

.remodal-is-initialized {
  /* Disable Anti-FOUC */
  display: inline-block;
}

html.remodal-is-locked body {
  overflow: hidden;
  touch-action: none;
}

.remodal {
  background: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 40px 50px 55px 50px;
  text-align: left;
}
.remodal .close-btn {
  top: 15px;
  right: 15px;
}
.remodal .modal-title {
  margin-bottom: 35px;
}
.remodal.size-big {
  max-width: 840px;
}
.remodal.size-small {
  max-width: 390px;
  padding: 40px 30px 40px 30px;
}
.remodal.size-small .modal-title {
  margin-bottom: 25px;
}

.remodal-overlay {
  background: rgba(57, 57, 57, 0.34);
}

.login-remodal .form-content {
  margin-top: 40px;
}
.login-remodal .password-row {
  margin-bottom: 5px;
}

@media screen and (max-width: 1023px) {
  .remodal {
    padding: 40px 35px 40px 35px;
  }
}
@media screen and (max-width: 600px) {
  .remodal, .remodal.size-small, .remodal.size-big {
    padding: 30px 15px 25px 15px;
  }

  .login-remodal .form-content {
    margin-top: 20px;
  }
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    bottom: -15px;
    max-height: 0;
    max-width: 0;
    margin-top: 0;
  }
  30% {
    opacity: 0.8;
    bottom: -3px;
  }
  100% {
    opacity: 1;
    bottom: 0;
    max-height: 200px;
    margin-top: 12px;
    max-width: 400px;
  }
}
@keyframes fadeoutdown {
  0% {
    opacity: 1;
    bottom: 0;
  }
  30% {
    opacity: 0.2;
    bottom: -3px;
  }
  100% {
    opacity: 0;
    bottom: -15px;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}
.notyf__icon--alert, .notyf__icon--confirm {
  margin: 0 auto;
  position: relative;
  width: 34px;
  height: 34px;
  display: block;
  font-size: 0px;
  line-height: 0px;
  border-radius: 0px;
}

.notyf__icon--alert {
  background: url("../img/svg/icons/error-icon.svg") no-repeat center center/cover;
}
.notyf__icon--alert:after, .notyf__icon--alert:before {
  display: none;
}

.notyf__icon--confirm {
  background: url("../img/svg/icons/success-icon.svg") no-repeat center center/cover;
}
.notyf__icon--confirm:after, .notyf__icon--confirm:before {
  display: none;
}

.notyf__toast {
  display: block;
  overflow: hidden;
  animation: fadeinup 0.3s forwards;
  position: relative;
  border-radius: 0px;
}
.notyf__toast.notyf--alert {
  background: #ffffff;
  color: #393939;
  border: 1px solid #EC2424;
}
.notyf__toast.notyf--confirm {
  background: #DBFFDF;
  color: #393939;
  border: 1px solid #64BC40;
}
.notyf__toast.notyf--disappear {
  animation: fadeoutdown 0.3s 1 forwards;
  animation-delay: 0.25s;
}
.notyf__toast.notyf--disappear .notyf__message {
  opacity: 1;
  animation: fadeoutdown 0.3s 1 forwards;
  animation-delay: 0.1s;
}
.notyf__toast.notyf--disappear .notyf__icon {
  opacity: 1;
  animation: disappear 0.3s 1 forwards;
}

.notyf__wrapper {
  display: table;
  width: 100%;
  padding: 19px 19px 19px 19px;
}

.notyf__icon {
  display: table-cell;
  width: 34px;
  text-align: center;
  vertical-align: middle;
  font-size: 0px;
  line-height: 0px;
  opacity: 0;
  animation: appear 0.5s forwards;
  animation-delay: 0.25s;
}

.notyf__message {
  display: table-cell;
  width: calc(100% - 34px);
  width: 100%;
  padding-left: 16px;
  vertical-align: middle;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-style: normal;
  opacity: 0;
  animation: fadeinup 0.3s forwards;
  animation-delay: 0.15s;
}

.notyf {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 280px;
  color: #393939;
  z-index: 99999999;
}

/* Small screens */
@media only screen and (max-width: 736px) {
  .notyf__container {
    width: 90%;
    margin: 0 auto;
    display: block;
    right: 0;
    left: 0;
  }
}
@media screen and (max-width: 600px) {
  .notyf {
    bottom: 10px;
    right: 0px;
    width: 100%;
  }

  .notyf__toast {
    width: calc(100% - 20px) !important;
    max-width: 100% !important;
    margin: auto;
  }

  .notyf__message {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
  }
}
.notyf .notyf__wrapper {
  padding: 15px;
}
.notyf .notyf__toast.notyf--alert {
  background: #FFDBDB;
}

.main-content {
  padding-top: 40px;
  padding-bottom: 110px;
}

.package-send-info-seciton {
  padding: 50px 0px 40px 0px;
}
.package-send-info-seciton .content-width {
  column-count: 2;
  column-gap: 60px;
}
.package-send-info-seciton p {
  margin-bottom: 6px;
}
.package-send-info-seciton p:last-of-type {
  margin-bottom: 0px;
}

.tracking-button .icon {
  margin-right: 5px;
}
.tracking-button .text {
  transform: translateY(1px);
}

.plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #029CE0;
  width: 18px;
  height: 18px;
  transition: background-color 0.25s ease;
  border-radius: 50%;
}
.plus-icon:before {
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  background: #ffffff;
  transition: background-color 0.25s ease;
  border-radius: 2px;
}
.plus-icon:after {
  content: "";
  display: block;
  width: 2px;
  height: 8px;
  border-radius: 2px;
  background: #ffffff;
  transition: background-color 0.25s ease;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

blockquote {
  padding: 20px 20px 20px 50px;
  background: #D3EAF5;
  position: relative;
  font-size: 16px;
  line-height: 22px;
  border-radius: 2px;
  background: url("../img/svg/icons/info.svg") no-repeat #D3EAF5 top 15px left 15px/26px 27px;
}
blockquote.red {
  background: url("../img/svg/icons/warning.svg") no-repeat #FFDBDB top 15px left 15px/26px 28px;
}
blockquote.without-icon {
  padding-left: 20px;
}

.accordion-item .accordion-title .icon {
  transition: transform 0.25s ease;
}
.accordion-item.active > .accordion-title .icon {
  transform: rotate(180deg);
}

.password-field-outer {
  position: relative;
}
.password-field-outer .field {
  padding-right: 44px;
}
.password-field-outer .password-show-outer {
  position: absolute;
  transition: color 0.25s ease;
  cursor: pointer;
  top: 0px;
  right: 12px;
  bottom: 0px;
  margin: auto;
  width: 22px;
  height: 15px;
}
.password-field-outer .password-show-outer.active {
  color: #029CE0;
}

.form-content .city-col {
  display: flex;
  justify-content: space-between;
}
.form-content .city-col .zip {
  width: 108px;
}
.form-content .city-col .city {
  width: calc(100% - 128px);
}
.form-content .more-col-row .street-col:not(.full-width), .form-content .more-col-row .number-col:not(.full-width) {
  width: calc((50% - 30px) / 2);
}

.close-btn {
  position: absolute;
  width: 22px;
  height: 22px;
  padding: 5px;
  color: #393939;
  opacity: 0.6;
  transition: opacity 0.25s ease;
  cursor: pointer;
}
.close-btn .icon {
  display: block;
  width: 100%;
  height: 100%;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  a:hover.without-underline {
    text-decoration: underline;
  }

  .close-btn:hover {
    opacity: 1;
  }
}
@media (hover: hover) {
  a:hover.without-underline {
    text-decoration: underline;
  }

  .close-btn:hover {
    opacity: 1;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a:hover.without-underline {
    text-decoration: underline;
  }

  .close-btn:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 1023px) {
  .package-send-info-seciton .content-width {
    column-gap: 40px;
  }

  .main-content {
    padding-top: 20px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767px) {
  .form-content .more-col-row .street-col:not(.full-width), .form-content .more-col-row .number-col:not(.full-width) {
    width: calc((100% - 20px) / 2);
  }
}
@media screen and (max-width: 600px) {
  .package-send-info-seciton {
    padding: 25px 0px 15px 0px;
  }
  .package-send-info-seciton .content-width {
    column-count: 1;
  }

  .main-content {
    padding-bottom: 60px;
    padding-top: 0px;
  }

  .form-content .city-col .city {
    width: calc(100% - 123px);
  }
  .form-content .more-col-row .street-col:not(.full-width), .form-content .more-col-row .number-col:not(.full-width) {
    width: 100%;
  }
}
.blog-item .image-outer {
  display: block;
  margin-bottom: 15px;
  position: relative;
  height: 0px;
  padding-top: 64.3%;
}
.blog-item .image-outer img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.blog-item .top-row {
  margin-bottom: 14px;
  color: #757982;
}
.blog-item .top-row .separator {
  width: 4px;
  height: 4px;
  min-width: 4px;
  background: #757982;
  border-radius: 50%;
  margin: 0px 10px 0px 10px;
}
.blog-item .top-row .logo {
  width: 21px;
  min-width: 21px;
}
.blog-item .title {
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
}
.blog-item .text {
  margin-top: 10px;
  color: #757982;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-item .details-row {
  margin-top: 15px;
  font-size: 14px;
  line-height: 16px;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .blog-item .title:hover {
    text-decoration: none;
    color: #029CE0;
  }
}
@media (hover: hover) {
  .blog-item .title:hover {
    text-decoration: none;
    color: #029CE0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blog-item .title:hover {
    text-decoration: none;
    color: #029CE0;
  }
}
@media screen and (max-width: 767px) {
  .blog-item .top-row {
    margin-bottom: 7px;
  }
  .blog-item .top-row .logo, .blog-item .top-row .separator {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .blog-item .top-row {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 16px;
  }
  .blog-item .title {
    font-size: 18px;
    line-height: 22px;
  }
  .blog-item .text {
    -webkit-line-clamp: 2;
  }
  .blog-item .details-row {
    margin-top: 10px;
  }
}
.main-header:not(.opened-fix-header) {
  margin-bottom: 0px;
}
.main-header:not(.opened-fix-header) .menu-content {
  box-shadow: none;
}
.main-header:not(.opened-fix-header) .menu-content .logo-content .arrow-outer {
  display: none;
}

.main-content.home-page-content {
  padding-bottom: 0px !important;
}

.package-calculator-section h1 .big-text {
  font-size: 46px;
  line-height: 56px;
  margin-bottom: 11px;
}

.home-section {
  padding: 60px 0px 60px 0px;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
}

.partners-section {
  padding: 30px 0px 30px 0px;
}
.partners-section .inner-content {
  gap: 30px 0px;
}
.partners-section .partner-item {
  position: relative;
  width: calc(100% / 7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-section .partner-item:after {
  content: "";
  width: 1px;
  height: 28px;
  background: #d8d8d8;
  opacity: 0.3;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
.partners-section .partner-item:last-of-type:after, .partners-section .partner-item:nth-of-type(7n + 7):after {
  display: none;
}

.sending-process-section .section-title {
  margin-bottom: 0px;
}
.sending-process-section .process-content {
  max-width: 1115px;
  margin: 114px auto 0px auto;
  gap: 45px;
  grid-template-columns: repeat(4, calc((100% - 135px) / 4));
}
.sending-process-section .process-item {
  position: relative;
}
.sending-process-section .process-item .index {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 5px;
}
.sending-process-section .process-item .image-outer {
  max-height: 185px;
  margin-bottom: 25px;
}
.sending-process-section .process-item .image-outer img {
  position: relative;
  z-index: 2;
}
.sending-process-section .process-item .arrow {
  color: #029CE0;
  position: absolute;
  bottom: 100%;
  left: 100%;
  z-index: 3;
}
.sending-process-section .process-item.process-item-1 {
  z-index: 4;
}
.sending-process-section .process-item.process-item-1 .arrow {
  transform: translate(-40px, 8px);
}
.sending-process-section .process-item.process-item-2 {
  z-index: 3;
}
.sending-process-section .process-item.process-item-2 .arrow {
  transform: translate(-44px, 18px);
}
.sending-process-section .process-item.process-item-3 {
  z-index: 2;
}
.sending-process-section .process-item.process-item-3 .arrow {
  transform: translate(-51px, 14px);
}
.sending-process-section .process-item:not(.process-item-4) .image-outer {
  transform: translateX(-15px);
}

.why-choose-us-section .thin-content {
  max-width: 940px;
}
.why-choose-us-section .items {
  gap: 30px;
  grid-template-columns: repeat(2, calc((100% - 50px) / 2));
}
.why-choose-us-section .item .icon-content {
  min-width: 47px;
}
.why-choose-us-section .item .choose-us-icon-2 {
  transform: translateY(-2px);
}
.why-choose-us-section .item .choose-us-icon-3, .why-choose-us-section .item .choose-us-icon-4 {
  transform: translateY(-5px);
}
.why-choose-us-section .item .text {
  padding-left: 15px;
}

.information-before-order-section {
  padding: 110px 0px 110px 0px;
}
.information-before-order-section .items {
  gap: 40px;
  grid-template-columns: repeat(4, calc((100% - 120px) / 4));
}
.information-before-order-section .item {
  box-shadow: 2px 12px 20px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.information-before-order-section .item .image-outer {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 160px;
  display: block;
  overflow: hidden;
}
.information-before-order-section .item .image-outer img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.information-before-order-section .item .text-content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #ffffff;
  padding: 23px 34px 57px 34px;
  position: relative;
}
.information-before-order-section .item .text-content .title {
  font-weight: 400;
  color: #393939;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: none;
}
.information-before-order-section .item .text-content .more-link {
  position: absolute;
  display: inline-block;
  bottom: 13px;
  right: 18px;
}

.blog-section .inner-content {
  border-top: 2px solid #EBE4E2;
  padding: 50px 0px 60px 0px;
  gap: 15px 50px;
  grid-template-columns: 250px calc(100% - 300px);
  grid-template-rows: auto 1fr;
  grid-template-areas: "title articles" "more articles";
}
.blog-section .section-title {
  margin-bottom: 0px;
  text-align: left;
  grid-area: title;
}
.blog-section .more-link {
  grid-area: more;
}
.blog-section .articles-content {
  grid-area: articles;
  gap: 30px;
  grid-template-columns: repeat(3, calc((100% - 60px) / 3));
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .information-before-order-section .item .text-content .title:hover {
    color: #029CE0;
  }
}
@media (hover: hover) {
  .information-before-order-section .item .text-content .title:hover {
    color: #029CE0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .information-before-order-section .item .text-content .title:hover {
    color: #029CE0;
  }
}
@media screen and (max-width: 1280px) {
  .information-before-order-section {
    padding: 80px 0px 80px 0px;
  }
  .information-before-order-section .items {
    gap: 25px;
    grid-template-columns: repeat(4, calc((100% - 75px) / 4));
  }
}
@media screen and (max-width: 1023px) {
  .partners-section .inner-content {
    gap: 20px 0px;
  }
  .partners-section .partner-item {
    width: calc(100% / 5);
  }
  .partners-section .partner-item:nth-of-type(7n + 7):after {
    display: block;
  }
  .partners-section .partner-item:nth-of-type(5n + 5):after, .partners-section .partner-item:last-of-type:after {
    display: none;
  }

  .sending-process-section .process-content {
    margin-top: 50px;
    gap: 50px 60px;
    grid-template-columns: repeat(2, calc(50% - 30px));
  }
  .sending-process-section .process-item .arrow {
    display: none;
  }

  .information-before-order-section {
    padding: 60px 0px 60px 0px;
  }
  .information-before-order-section .items {
    gap: 40px;
    grid-template-columns: repeat(2, calc(50% - 20px));
  }

  .blog-section .inner-content {
    gap: 0px;
    grid-template-columns: 100%;
    grid-template-areas: "title" "articles" "more";
  }
  .blog-section .section-title {
    text-align: center;
    margin-bottom: 30px;
  }
  .blog-section .more-link {
    justify-self: flex-end;
    margin-top: 30px;
  }
  .blog-section .articles-content {
    gap: 20px;
    grid-template-columns: repeat(3, calc((100% - 40px) / 3));
  }
}
@media screen and (max-width: 767px) {
  .partners-section .partner-item {
    width: calc(100% / 3);
  }
  .partners-section .partner-item:nth-of-type(5n + 5):after {
    display: block;
  }
  .partners-section .partner-item:nth-of-type(3n + 3):after, .partners-section .partner-item:last-of-type:after {
    display: none;
  }

  .home-section {
    padding: 50px 0px 50px 0px;
  }

  .why-choose-us-section .items {
    grid-template-columns: 100%;
  }
  .why-choose-us-section .item .icon-outer {
    width: 73px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
  }
  .why-choose-us-section .item .text {
    padding: 0px;
    width: calc(100% - 73px);
  }

  .blog-section .articles-content {
    display: block;
    gap: 0px;
  }
  .blog-section .blog-item {
    width: 100%;
    margin-bottom: 12px;
    padding: 0px 8px 12px 8px;
    border-bottom: 1px solid #EBE4E2;
  }

  .home-page-content .package-calculator-section {
    box-shadow: none;
  }
}
@media screen and (max-width: 600px) {
  .section-title {
    margin-bottom: 30px;
  }

  .package-calculator-section h1, .package-calculator-section .like-h1 {
    display: inline;
  }
  .package-calculator-section h1 .big-text, .package-calculator-section .like-h1 .big-text {
    font-size: 20px;
    line-height: 26px;
  }

  .partners-section {
    padding: 20px 0px 20px 0px;
  }
  .partners-section .partner-item img.loaded {
    transform: scale(0.72);
  }

  .home-section {
    padding: 40px 0px 40px 0px;
  }

  .sending-process-section .process-content {
    margin-top: 28px;
    gap: 28px 0px;
    grid-template-columns: 100%;
  }
  .sending-process-section .process-item {
    display: flex;
    align-items: flex-start;
  }
  .sending-process-section .process-item .image-outer {
    padding-right: 25px;
    width: 135px;
    min-width: 135px;
    max-width: 135px;
    margin: 0px;
  }
  .sending-process-section .process-item:not(.process-item-4) .image-outer {
    transform: none;
  }

  .information-before-order-section {
    padding: 40px 0px 40px 0px;
  }
  .information-before-order-section .items {
    gap: 16px;
    grid-template-columns: 100%;
  }
  .information-before-order-section .section-title {
    margin-bottom: 20px;
  }
  .information-before-order-section .item {
    box-shadow: none;
    border-radius: 0px;
    display: flex;
  }
  .information-before-order-section .item .image-outer {
    width: 107px;
    height: auto;
    position: relative;
    z-index: 2;
    border-radius: 8px 0px 0px 8px;
  }
  .information-before-order-section .item .text-content {
    width: 100%;
    min-height: 90px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    box-shadow: 2px 12px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0px 8px 8px 0px;
    padding: 17px 20px 30px 15px;
  }
  .information-before-order-section .item .text-content .title {
    color: #393939;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  .information-before-order-section .item .text-content .more-link {
    position: absolute;
    bottom: 8px;
    right: 12px;
  }

  .blog-section .inner-content {
    padding: 0px 0px 25px 0px;
    border-top: none;
  }
  .blog-section .section-title {
    margin-bottom: 20px;
  }
  .blog-section .more-link {
    margin-top: 0px;
  }
}