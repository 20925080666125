@import './inc/style.scss';
@import './inc/blog-item.scss';

.main-header {
    &:not(.opened-fix-header) {
        margin-bottom:0px;

        .menu-content {
            box-shadow:none;

            .logo-content {
                .arrow-outer {
                    display:none;
                }
            }
        }
    }
}

.main-content.home-page-content {
    padding-bottom:0px!important;
}

.package-calculator-section {
    h1 {
        .big-text {
            font-size:46px;
            line-height:56px;
            margin-bottom:11px;
        }
    }
}

.home-section {
    padding:60px 0px 60px 0px;

}

.section-title {
    text-align:center;
    margin-bottom:40px;
}

.partners-section {
    padding:30px 0px 30px 0px;

    .inner-content {
        gap:30px 0px;
    }

    .partner-item {
        position:relative;
        width:calc(100% / 7);
        display:flex;
        align-items: center;
        justify-content: center;
        
        &:after {
            content:'';
            width:1px;
            height:28px;
            background:#d8d8d8;
            opacity:0.3;
            position:absolute;
            top:0px;
            right:0px;
            bottom:0px;
            margin:auto;
        }

        &:last-of-type, &:nth-of-type(7n + 7) {
            &:after {
                display:none;
            }
        }
    }
}

.sending-process-section {
    .section-title {
        margin-bottom:0px;
    }

    .process-content {
        max-width:1115px;
        margin:114px auto 0px auto;
        gap:45px;
        grid-template-columns: repeat(4, calc((100% - 135px) / 4));
    }

    .process-item {
        position:relative;

        .index {
            font-size:20px;
            line-height:22px;
            margin-bottom:5px;
        }

        .image-outer {
            max-height:185px;
            margin-bottom:25px;

            img {
                position:relative;
                z-index:2;
            }
        }

        .arrow {
            color:$blue;
            position:absolute;
            bottom:100%;
            left:100%;
            z-index:3;
        }

        &.process-item-1 {
            z-index:4;
            
            .arrow {
                transform:translate(-40px, 8px)
            }
        }

        &.process-item-2 {
            z-index:3;
            
            .arrow {
                transform:translate(-44px, 18px)
            }
        }

        &.process-item-3 {
            z-index:2;

            .arrow {
                transform:translate(-51px, 14px)
            }
        }

        &:not(.process-item-4) {
            .image-outer {
                transform:translateX(-15px);
            }
        }
    }
}

.why-choose-us-section {

    .thin-content {
        max-width:940px;
    }

    .items {
        gap:30px;
        grid-template-columns: repeat(2, calc((100% - 50px) / 2));
    }

    .item {
        .icon-content {
            min-width:47px;
        }

        .choose-us-icon-2 {
            transform:translateY(-2px);
        }

        .choose-us-icon-3, .choose-us-icon-4 {
            transform:translateY(-5px);
        }

        .text {
            padding-left:15px;
        }
    }
}

.information-before-order-section {
    padding:110px 0px 110px 0px;

    .items {
        gap:40px;
        grid-template-columns: repeat(4, calc((100% - 120px) / 4));
    }

    .item {
        box-shadow: 2px 12px 20px 0 rgba(0,0,0,0.10);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        
        .image-outer {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height:160px;
            display:block;
            overflow:hidden;

            img {
                object-fit:cover;
                object-position: center center;
                width:100%;
                height:100%;
            }
        }

        .text-content {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background:$white;
            padding:23px 34px 57px 34px;
            position:relative;

            .title {
                font-weight:$normal-fweight;
                color:$primary-color;
                font-size:20px;
                line-height:24px;
                letter-spacing: $secondary-lspacing;
                text-decoration:none;
            }

            .more-link {
                position:absolute;
                display:inline-block;
                bottom:13px;
                right:18px;
            }
        }
    }
}

.blog-section {
    .inner-content {
        border-top:2px solid $beige;
        padding:50px 0px 60px 0px;
        gap:15px 50px;
        grid-template-columns: 250px calc(100% - 300px);
        grid-template-rows: auto 1fr;
        grid-template-areas: 
        "title articles"
        "more articles";
    }

    .section-title {
        margin-bottom:0px;
        text-align:left;
        grid-area:title;
    }

    .more-link {
        grid-area: more;
    }

    .articles-content {
        grid-area: articles;
        gap:30px;
        grid-template-columns: repeat(3, calc((100% - 60px) / 3));
    }
}

@include hoverStatements {
    .information-before-order-section {
        .item {
            .text-content {
                .title {
                    &:hover {
                        color:$blue;
                    }
                }
            }
        }
    }
}

@include breakpoint(1280px) {
    .information-before-order-section {
        padding:80px 0px 80px 0px;
        .items {
            gap:25px;
            grid-template-columns: repeat(4, calc((100% - 75px) / 4));
        }
    }
}

@include below-lying-tablet {
    .partners-section {
        .inner-content {
            gap: 20px 0px; 
        }

        .partner-item {
            width:calc(100% / 5);

            &:nth-of-type(7n + 7) {
                &:after {
                    display:block;
                }
            }

            &:nth-of-type(5n + 5), &:last-of-type {
                &:after {
                    display:none;
                }
            }
        }
    }

    .sending-process-section {
        .process-content {
            margin-top:50px;
            gap: 50px 60px;

            grid-template-columns: repeat(2, calc(50% - 30px));
        }


        .process-item {
            .arrow {
                display:none;
            }
        }
    }

    .information-before-order-section {
        padding:60px 0px 60px 0px;

        .items {
            gap:40px;
            grid-template-columns: repeat(2, calc(50% - 20px));
        }
    }

    .blog-section {
        .inner-content {
            gap:0px;
            grid-template-columns: 100%;
            grid-template-areas: 
            "title"
            "articles"
            "more";
        }

        .section-title {
            text-align:center;
            margin-bottom:30px;
        }

        .more-link {
            justify-self:flex-end;
            margin-top:30px;
        }

        .articles-content {
            gap:20px;
            grid-template-columns: repeat(3, calc((100% - 40px) / 3));
        }
    }
}

@include below-standing-tablet {
    .partners-section {
        .partner-item {
            width:calc(100% / 3);

            &:nth-of-type(5n + 5) {
                &:after {
                    display:block;
                }
            }

            &:nth-of-type(3n + 3), &:last-of-type {
                &:after {
                    display:none;
                }
            }
        }
    }

    .home-section {
        padding:50px 0px 50px 0px;
    }

    .why-choose-us-section {
        .items {
            grid-template-columns: 100%;
        }

        .item {
            .icon-outer {
                width:73px;
                padding-right:15px;
                display:flex;
                justify-content:center;
            }

            .text {
                padding:0px;

                width:calc(100% - 73px);
            }
        }
    }

    .blog-section {
        .articles-content {
            display:block;
            gap:0px;
        }

        .blog-item {
            width:100%;
            margin-bottom:12px;
            padding:0px 8px 12px 8px;
            border-bottom:1px solid $beige;
        }
    }

    .home-page-content {
        .package-calculator-section {
            box-shadow: none;
        }
    }
}

@include mobile {
    .section-title {
        margin-bottom:30px;
    }

    .package-calculator-section {
        h1, .like-h1 {
            display:inline;

            .big-text {
                font-size:$h1-mobile-fsize;
                line-height:$h1-mobile-lheight;
            }
        }
    }

    .partners-section {
        padding:20px 0px 20px 0px;

        .partner-item {
            img {
                &.loaded {
                    transform:scale(0.72);
                }
            }
        }
    }

    .home-section {
        padding:40px 0px 40px 0px;
    }

    .sending-process-section {
        .process-content {
            margin-top:28px;
            gap: 28px 0px;
            grid-template-columns: 100%;
        }


        .process-item {
            display:flex;
            align-items:flex-start;

            .image-outer {
                padding-right:25px;
                width:135px;
                min-width:135px;
                max-width:135px;
                margin:0px;
            }

            &:not(.process-item-4) {
                .image-outer {
                    transform:none;
                }
            }
        }
    }
    
    .information-before-order-section {
        padding:40px 0px 40px 0px;
        
        .items {
            gap:16px;
            grid-template-columns: 100%;
        }

        .section-title {
            margin-bottom:20px;
        }

        .item {
            box-shadow: none;
            border-radius:0px;
            display:flex;

            .image-outer {
                width:107px;
                height:auto;
                position:relative;
                z-index:2;
                border-radius:8px 0px 0px 8px;
            }

            .text-content {
                width:100%;
                min-height:90px;
                background:$white;
                position:relative;
                z-index:1;
                box-shadow: 2px 12px 20px 0 rgba(0,0,0,0.10);
                border-radius:0px 8px 8px 0px;
                padding:17px 20px 30px 15px;

                .title {
                    color:$primary-color;
                    text-decoration: none;
                    font-size:18px;
                    line-height:22px;
                    font-weight:$semi-fweight;
                }

                .more-link {
                    position:absolute;
                    bottom:8px;
                    right:12px;
                }
            }
        }
    }

    .blog-section {
        .inner-content {
            padding:0px 0px 25px 0px;
            border-top:none;
        }

        .section-title {
            margin-bottom:20px;
        }

        .more-link {
            margin-top:0px;
        }
    }
}